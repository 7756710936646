<template>
  <v-app-bar dense flat app class="prd-app-bar" height="55">
    <v-container>
      <v-row class="justify-space-between align-center">
        <v-col>
          <h3>{{ $store.state.currentTitle }}</h3>
        </v-col>

        <v-col cols="auto">
          <img
            :src="$store.state.userSettings.darkMode ? clientInverseLogo : clientLogo"
            height="40px"
            class="mt-1"
            alt="Cardioguard"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
export default {
  data: () => ({
    clientLogo: require("@/assets/images/client/logo.svg"),
    clientInverseLogo: require("@/assets/images/client/logo_inverse.svg"),
  }),
};
</script>

<style lang="scss" scoped>
.prd-app-bar {
  border-bottom: 1px solid lightgray;
}
</style>
